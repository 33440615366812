import {
  DontHydrate,
  Heading,
  HeadingProps,
  Paragraph,
  ParagraphProps,
  type ComponentProps as DSComponentProps,
} from '@loveholidays/design-system';
import React, { Fragment } from 'react';
import { SxStyleProp } from 'theme-ui';

import { BlockContent as BlockContentType } from '@AuroraTypes';
import { hastToReact } from '@UX/Hast/Hast';
import { List, ListProps } from '@UX/List/List';

type ComponentProps<T> = T | { sx?: SxStyleProp };

interface BlockContentProps extends Pick<BlockContentType, 'content' | 'type'> {}

interface BlockContentHastProps {
  content: BlockContentType['content'];
  paragraphProps?: Partial<ComponentProps<ParagraphProps>>;
}

export const BlockContentHast: React.FC<BlockContentHastProps> = ({ content, paragraphProps }) => (
  <Fragment>
    {hastToReact(content, {
      h1: {
        Component: Heading,
        Props: {
          as: 'h1',
          variant: 'xlargebold',
          sx: {
            marginTop: ['xl', '2xl'],
            marginBottom: ['s', 'l'],
            '&:first-child': {
              marginTop: '0',
            },
            scrollMarginTop: ({ space }) => space.xl,
          },
        } as ComponentProps<HeadingProps>,
      },
      h2: {
        Component: Heading,
        Props: {
          as: 'h2',
          variant: 'largebold',
          sx: {
            marginTop: ['l', 'xl'],
            marginBottom: ['s', 'l'],
            '&:first-child': {
              marginTop: '0',
            },
            scrollMarginTop: ({ space }) => space.xl,
          },
        } as ComponentProps<HeadingProps>,
      },
      h3: {
        Component: Heading,
        Props: {
          as: 'h3',
          variant: 'mediumbold',
          sx: {
            marginTop: ['s', 'l'],
            marginBottom: 's',
            '&:first-child': {
              marginTop: '0',
            },
            scrollMarginTop: ({ space }) => space.xl,
          },
        } as ComponentProps<HeadingProps>,
      },
      p: {
        Component: Paragraph,
        Props: {
          variant: 'medium',
          sx: {
            marginBottom: 's',
          },
          ...paragraphProps,
        } as ComponentProps<ParagraphProps>,
      },
      ul: {
        Component: List,
        Props: {
          listType: 'unordered',
          sx: {
            fontSize: 'l',
            marginBottom: 's',
          },
        } as ComponentProps<ListProps>,
      },
      ol: {
        Component: List,
        Props: {
          listType: 'ordered',
          sx: {
            fontSize: 'l',
            marginBottom: 's',
          },
        } as ComponentProps<ListProps>,
      },
      b: {
        Component: 'strong',
      },
      table: {
        Component: 'table',
        Props: {
          sx: {
            width: '100%',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'backgroundDark',

            th: {
              padding: 'xs',
              backgroundColor: 'backgroundDark',
              color: 'white',
              verticalAlign: 'middle',
              fontWeight: 'bold',
            },
            'tr:nth-child(even)': {
              backgroundColor: 'backgroundLight',
            },
            td: {
              padding: 'xs',
            },
          },
        },
      },
      img: {
        Component: 'img',
        Props: {
          sx: {
            width: '100%',
          },
        },
      },
    })}
  </Fragment>
);

export const BlockContent: React.FC<BlockContentProps & DSComponentProps> = ({
  content,
  type,
  className,
}) => (
  <DontHydrate
    as="section"
    data-component={type}
    className={className}
  >
    <BlockContentHast content={content} />
  </DontHydrate>
);
